import styled, { css } from 'styled-components';
import { Button } from '../Button';
import { XCloseIcon } from '../Icons';
import { ICON_CLASS } from '../Icons/tokens';
import { Stack } from '../Layout';
import { BaseColor, theme } from '../theme';
import { getColorCode, getFontSizeWithStrokeWidth, getInterFontWeightCSS, negateVariable, resolveCssVariable } from '../theme/utils';
import { tokens } from './tokens';
import { Variant, BadgeGroupSize, BadgePlacement } from './types';
export const shadeResolver = (tokens) => {
    return parseInt(resolveCssVariable(tokens));
};
export const StyledClearIcon = styled(XCloseIcon) `
  order: 4;
`;
export const StyledButton = styled(Button) `
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${({ size, $isChildrenPresent }) => $isChildrenPresent ? tokens.BadgesRoot.size[size].padding : theme.spaces.x4};
  height: ${({ size }) => tokens.BadgesRoot.size[size].height};
  ${({ rounded, size }) => (rounded ? `width: ${tokens.BadgesRoot.size[size].height};` : '')}
  font-size: ${({ size }) => tokens.BadgesRoot.size[size].fontSize};
  ${getInterFontWeightCSS(tokens.BadgesRoot.fontWeight)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  transition: all 0.2s ease-in-out;

  ${({ disabled, $variant, tone, filled }) => {
    const borderRadius = tokens.BadgesRoot.variant[$variant]?.borderRadius ?? tokens.BadgesRoot.borderRadius;
    const colorShade = shadeResolver(tokens.BadgesRoot.variant[$variant].colorShade);
    const backgroundShade = shadeResolver(tokens.BadgesRoot.variant[$variant].backgroundShade);
    const borderShade = getColorCode(Variant.badgeModern === $variant ? BaseColor.gray : tone, shadeResolver(tokens.BadgesRoot.variant[$variant].borderShade));
    const borderWidth = tokens.BadgesRoot.borderWidth;
    return disabled
        ? 'cursor: not-allowed;'
        : `
        border-radius: ${borderRadius};
        &:hover,
        &&:focus,
        &&& {
          color: ${colorShade !== 0 ? getColorCode(tone, colorShade) : tokens.BadgesRoot.variant[$variant].color};
          background: ${filled
            ? [Variant.badgeModern, Variant.pillOutline].some((shade) => shade === $variant)
                ? theme.colors.white
                : getColorCode(tone, backgroundShade)
            : theme.colors.white};
          border: ${$variant === Variant.badgeModern
            ? `${borderWidth} solid ${borderShade}`
            : `${$variant === Variant.pillOutline ? tokens.BadgesRoot.outlineBorderwidth : borderWidth} solid ${borderShade}`};
          outline: none;
          box-shadow: none;
        }
      `;
}}

  /** Style icon based on size */
  && .${ICON_CLASS}:not(${StyledClearIcon}) {
    color: ${({ tone, $variant }) => shadeResolver(tokens.BadgesRoot.variant[$variant].iconShade) === 0
    ? tokens.BadgesRoot.white
    : getColorCode(tone, shadeResolver(tokens.BadgesRoot.variant[$variant].iconShade))};

    ${({ $iconPlacement, $isChildrenPresent }) => $isChildrenPresent &&
    ($iconPlacement === 'left'
        ? `margin-left: ${negateVariable(theme.spaces.x3)};`
        : `margin-right: ${negateVariable(theme.spaces.x3)};`)}
  }

  ${StyledClearIcon} {
    color: ${({ tone, $variant }) => $variant === Variant.badgeModern
    ? getColorCode(BaseColor.gray, shadeResolver(tokens.BadgesRoot.variant[$variant].closeIconShade))
    : [Variant.pillDark].some((shade) => shade === $variant)
        ? tokens.BadgesRoot.variant[$variant].closeIconShade
        : getColorCode(tone, shadeResolver(tokens.BadgesRoot.variant[$variant].closeIconShade))};
    cursor: pointer;
    ${({ $closable }) => $closable && `margin-right: -5px`}
  }

  && .${ICON_CLASS} {
    width: ${({ size }) => tokens.BadgesRoot.size[size].iconFontSize};
    height: ${({ size }) => tokens.BadgesRoot.size[size].iconFontSize};
    ${({ size }) => getFontSizeWithStrokeWidth(tokens.BadgesRoot.size[size].iconFontSize)};
  }
`;
export const StyledContent = styled(Stack) `
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ $title }) => !$title && `padding-left: ${tokens.BadgeGroup.gap.content.leading};`}
  gap: ${({ $badgePlacement }) => $badgePlacement !== BadgePlacement.left
    ? tokens.BadgeGroup.gap.content.leading
    : tokens.BadgeGroup.gap.content.trailing};
`;
// Utility function to get BadgeGroupContainer styling based on variant and tone
const getBadgeContainerStyle = ({ disabled, $variant, $tone }) => {
    if (disabled) {
        return 'cursor: not-allowed;';
    }
    const isBadgeModern = $variant === Variant.badgeModern;
    const borderRadius = isBadgeModern
        ? tokens.BadgeGroup.borderRadius
        : (tokens.BadgesRoot.variant[$variant]?.borderRadius ?? tokens.BadgesRoot.borderRadius);
    const colorShade = shadeResolver(tokens.BadgesRoot.variant[$variant].colorShade);
    const backgroundShade = shadeResolver(tokens.BadgesRoot.variant[$variant].backgroundShade);
    const borderColor = getColorCode(isBadgeModern ? BaseColor.gray : $tone, shadeResolver(tokens.BadgesRoot.variant[$variant].borderShade));
    const borderWidth = $variant === Variant.pillOutline ? tokens.BadgesRoot.outlineBorderwidth : tokens.BadgesRoot.borderWidth;
    return css `
    border-radius: ${borderRadius};

    &:hover,
    &&:focus,
    &&& {
      color: ${colorShade !== 0 ? getColorCode($tone, colorShade) : tokens.BadgesRoot.variant[$variant].color};
      background: ${[Variant.badgeModern, Variant.pillOutline].includes($variant)
        ? theme.colors.white
        : getColorCode($tone, backgroundShade)};
      border: ${borderWidth} solid ${borderColor};
      outline: none;
      box-shadow: none;
    }

    ${$variant !== Variant.badgeModern &&
        `
      &&:hover {
        background: ${getColorCode($tone, $variant === Variant.pillLight ? backgroundShade + 50 : backgroundShade)};
      }
    `}
  `;
};
export const StyledBadgeGroupContainer = styled(Stack) `
  box-sizing: content-box;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  gap: ${tokens.BadgeGroup.gap.rootContainer};
  padding: ${({ $badgePlacement }) => tokens.BadgeGroup.padding[$badgePlacement]};
  font-size: ${({ $size }) => tokens.BadgesRoot.size[$size].fontSize};
  ${getInterFontWeightCSS(tokens.BadgesRoot.fontWeight)};
  cursor: ${({ onClick }) => (onClick ? 'pointer' : 'default')};
  transition: all ${theme.transition.s};
  ${({ rounded, $size }) => rounded && `width: ${tokens.BadgesRoot.size[$size].height};`}

  ${StyledButton} {
    gap: ${({ $size }) => $size === BadgeGroupSize.l ? tokens.BadgeGroup.gap.content.leading : tokens.BadgeGroup.gap.content.trailing};
  }

  ${getBadgeContainerStyle}

  ${StyledContent} {
    .${ICON_CLASS}:not(button .${ICON_CLASS}) {
      ${({ $size }) => getFontSizeWithStrokeWidth(tokens.BadgeGroup.size[$size].iconFontSize)};
      color: ${({ $variant, $tone }) => $variant === Variant.badgeModern
    ? tokens.BadgeGroup.iconModernBadgeColor
    : getColorCode($tone, shadeResolver(tokens.BadgesRoot.variant[$variant].iconShade))};
    }
  }
`;
