import styled from 'styled-components';
import { Button } from '../Button';
import { StyledCheckboxRoot } from '../Checkbox/styled';
import { XCloseIcon } from '../Icons';
import { ICON_CLASS } from '../Icons/tokens';
import { getInterFontWeightCSS } from '../theme';
import { getColorCode, getFontSizeWithStrokeWidth } from '../theme/utils';
import { tokens } from './tokens';
export const StyledClearIcon = styled(XCloseIcon) `
  color: ${tokens.TagsRoot.iconColor};
  height: ${({ size }) => tokens.TagsRoot.size[size].iconFontSize};
  width: ${({ size }) => tokens.TagsRoot.size[size].iconFontSize};
  cursor: pointer;
  display: flex;
`;
export const StyledIconWrapper = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  height: ${({ $size }) => tokens.TagsRoot.size[$size].iconFontSize};
  width: ${({ $size }) => tokens.TagsRoot.size[$size].iconFontSize};
  .${ICON_CLASS} {
    color: ${tokens.TagsRoot.iconColor};
    ${({ $size }) => getFontSizeWithStrokeWidth(tokens.TagsRoot.size[$size].iconFontSize)};
    ${getInterFontWeightCSS(tokens.TagsRoot.fontWeight)};
  }
`;
export const StyledButton = styled(Button) `
  ${getInterFontWeightCSS(tokens.TagsRoot.fontWeight)};
  border-radius: ${tokens.TagsRoot.borderRadius};
  padding: ${({ size }) => tokens.TagsRoot.size[size].padding};
  height: ${({ size }) => tokens.TagsRoot.size[size].height};
  font-size: ${({ size }) => tokens.TagsRoot.size[size].fontSize};
  gap: ${tokens.TagsRoot.gap};
  &&&,
  &&&:hover,
  &&&:focus {
    color: ${({ disabled }) => getColorCode('gray', disabled ? 500 : 700)};
    background: ${tokens.TagsRoot.backgroundColor};
    outline: none;
  }
  &&&:hover {
    background: ${tokens.TagsRoot.hover.backgroundColor};
  }
  &&&:active,
  &&&:focus {
    background: ${({ disabled }) => (disabled ? getColorCode('gray', 25) : 'transparent')};
  }
  ${({ disabled }) => disabled
    ? 'cursor: not-allowed;'
    : `
    ${StyledCheckboxRoot}{  
      border: 1px solid ${getColorCode('gray', 300)};
    }
  `}
  ${({ $closable, $isIcon, disabled }) => $closable &&
    $isIcon &&
    `
    ${StyledClearIcon} {
      display: none;
    }
    &&&:hover {
      ${StyledClearIcon} {
        display: ${disabled ? 'none' : 'flex'};
      }
      ${StyledIconWrapper} {
        display: none;
      }
    }
  `}
`;
export const StyledNumberContainer = styled.div `
  background: ${tokens.TagsRoot.numberContainer.backgroundColor};
  color: ${({ disabled }) => getColorCode('gray', disabled ? 500 : 700)};
  border-radius: ${({ countCircle }) => (countCircle ? '50%' : tokens.TagsRoot.countBorderRadius)};
  ${({ countCircle, size }) => countCircle
    ? `
    height: ${tokens.TagsRoot.circleSize};
    min-width: ${tokens.TagsRoot.circleSize};
    line-height: ${tokens.TagsRoot.circleSize};
    font-size: ${tokens.TagsRoot.circleFontSize};
  `
    : ` 
        padding: 1px ${tokens.TagsRoot.size[size].countPadding}
        height: 100%; 
        min-width: ${tokens.TagsRoot.circleSize};
      `};
`;
